import * as React from 'react';

import { User } from '@prisma/client';
import NextImage from 'next/image';
import { IconProps, Moon, Sun, SunHorizon } from 'phosphor-react';

import { DashboardCard } from '@/components/dashboard';
import { AnimalEnergyImage, PhaseJump, PhaseProgress } from '@/components/phases';
import { Carousel, PhaseColorText } from '@/components/ui';
import { PhaseName } from '@/types/28';
import { timeOfDay } from '@/utils/date';
import { imgixLoader } from '@/utils/images';
import { capitalize } from '@/utils/string';

import { RouterOutput } from '../../../trpc/router';

const timeIconMap: Record<
  ReturnType<typeof timeOfDay>,
  React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
> = {
  morning: SunHorizon,
  afternoon: Sun,
  evening: Moon,
};

type DashboardCycleProps = {
  dbUser: User;
  phaseday: RouterOutput['users']['workout']['phaseday'];
};

export const DashboardCycle = React.memo<DashboardCycleProps>(({ dbUser, phaseday }) => {
  const time = timeOfDay();
  const TimeIcon = timeIconMap[time];

  const phase = phaseday?.phase;

  return (
    <header className='relative overflow-hidden py-6 md:py-8'>
      {phase && phase.name && (
        <NextImage
          alt={phase.name}
          className='object-cover'
          fill
          priority={true}
          sizes='100vw'
          src={`/images/phase/${phase.name.toLowerCase()}--dashboard-header.jpg`}
        />
      )}
      <Carousel itemGap={16}>
        <DashboardCard>
          <TimeIcon className='h-6 w-6' />
          <div className='text-xs font-josefin tracking-tight mt-2'>
            Good {time}
            {dbUser.firstName ? `, ${capitalize(dbUser.firstName)}` : '!'}
          </div>
          <PhaseColorText className='font-bodoniBold tracking-tight text-6xl mt-2 mb-0' phaseName={phase.name}>
            {phase.heading}
          </PhaseColorText>
          <PhaseProgress
            className='w-[260px] lg:w-[300px]'
            currentPhase={phase.name || ''}
            currentPhaseDay={phaseday.number}
            dynamicColors={false}
            height={5}
          />
          <div className='mt-4 text-xs font-josefin tracking-tight'>
            You&rsquo;re in <br />
            <strong className='text-base mt-1 block' data-testid='phase-name-text'>
              {phase?.name} phase
            </strong>
          </div>
          {!dbUser.cycleLunar && <PhaseJump phaseName={phase.name as PhaseName} userId={dbUser.id} />}
        </DashboardCard>
        <DashboardCard>
          {phaseday.image && (
            <NextImage
              alt={phaseday.heading}
              className='rounded-full object-cover'
              height={104}
              loader={imgixLoader}
              src={`/${phaseday.image.fileName}`}
              width={104}
            />
          )}
          <PhaseColorText className='font-josefin text-xs tracking-tighter font-bold mt-4' phaseName={phase.name}>
            Your Body Today
          </PhaseColorText>
          <h2 className='font-josefin tracking-tighter font-bold text-khaki-1 mb-0 mt-1'>{phaseday.heading}</h2>
          <div className='text-sm mt-1'>{phaseday.physical}</div>
        </DashboardCard>
        <DashboardCard>
          <PhaseColorText phaseName={phase.name}>
            <AnimalEnergyImage animalEnergy={phaseday.animalEnergy} />
          </PhaseColorText>
          <PhaseColorText className='font-josefin text-xs tracking-tighter font-bold mt-4' phaseName={phase.name}>
            Your Emotions Today
          </PhaseColorText>
          <h2 className='font-josefin tracking-tighter font-bold text-khaki-1 m-1 mb-0 capitalize'>
            {phaseday.animalEnergy.toLowerCase()} Energy
          </h2>
          <div className='text-sm mt-1'>{phaseday.emotional}</div>
        </DashboardCard>
      </Carousel>
    </header>
  );
});

DashboardCycle.displayName = 'DashboardCycle';
